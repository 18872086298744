
exports.linkResolver = (doc) => {
  if (doc.type === 'blog_content_model') {
    return `/${doc.uid}`
  }
  if (doc.type === 'article_landing_page') {
    return `/${doc.uid}`
  }
  if (doc.type === 'landing_page') {
    return `/shop`
  }
  if (doc.type === 'category') {
    return `/${doc.uid}`
  }
  if (doc.type === 'event_landing_page') {
    return `/${doc.uid}`
  }
  return '/'
}
